import { gql } from '@apollo/client';

export const GET_META_DATA = gql`
  query getMetaData {
    metaData {
      countries
      currencyValues
      docsRelease
      docsRouting
      paymentDurations
      paymentTerms
      states
    }
  }
`;

export const GET_IN_APPLICATION_DETAILS = gql`
  query getApplicationDetails($id: ID!) {
    applicationDetails(id: $id) {
      message
      application {
        buyerInfo {
          pending {
            index
            key
          }
          present
        }
        companyInfo {
          pending {
            index
            key
          }
          present
        }
        financialInfo {
          cibil {
            present
            verified
          }
          gst {
            gstNo
            recordStatus
          }
          itr {
            files {
              name
              url
              documentId
            }
            status
          }
        }
        onboardingStage
      }
    }
  }
`;

export const GET_MX_APPLICATION_STATUS = gql`
  query getMXApplicationStatus($id: ID!) {
    applicationStatus(id: $id) {
      message
      application {
        onboardingStage
        buyerInfo {
          pending {
            index
            key
          }
          present
        }
        companyInfo {
          pending {
            index
            key
          }
          present
        }
        financialInfo {
          company {
            pending
            present
          }
          shareholders {
            pending {
              shareholderId
              key
            }
            present
          }
        }
        legalDocs
      }
    }
  }
`;

export const GET_SCF_APPLICATION_DETAILS = gql`
  query GetScfApplicationDetails($id: String, $customerId: String) {
    scfApplicationDetails(businessId: $id, customerId: $customerId) {
      application {
        uid
        mailSent
        mailSentDate
        bankDetails {
          files {
            name
            url
            documentId
          }
          plaidLinked
          plaidFailed
          status
        }
        businessDetails {
          website
          ecomSeller {
            isEcomSeller
            ecomSellerLink
          }
          businessIdentifierKey
          businessIdentifierValue
          companyName
          dba
          detailsPending
          dripProfile {
            designation
            id
            onboardingStage
            profileType
          }
          ein
          id
          location {
            address
            country {
              name
              parentTagId
              type
            }
            state {
              name
              parentTagId
              type
            }
            city {
              name
            }
            zipCode
          }
          physicalOperationsAddress {
            sameAsLocation
            address
            country
            city
            state
            zipCode
          }
          productCategory
          salesContact {
            email
            name
            phNumber
          }
          scfOwners {
            email
            name
            ownership
            phone {
              countryCode
              phoneNumber
            }
            userId
          }
          status
          typeOfGoods
          yearIncorporated
        }
        financialDetails {
          files {
            name
            url
            documentId
          }
          status
        }
        certificateOfIncorporation {
          files {
            name
            url
            documentId
          }
          status
        }
        dbaDocuments {
          files {
            name
            url
            documentId
          }
          status
        }
        onboardingStage
        personalDetails {
          consumerCreditConsent
          detailsPending
          email
          id
          name
          phone {
            countryCode
            phoneNumber
          }
          onfidoUploadCompleted
          title
          dob
          citizenship {
            id
            name
            type
            value
          }
          identifiers {
            id
            name
            type
            value
          }
        }
      }
      message
    }
  }
`;

export const GET_STATE_OPTIONS = gql`
  query getStates($id: ID!) {
    states(id: $id)
  }
`;

export const GET_SCF_META_DATA = gql`
  query scfMetaData {
    scfMetaData {
      allStates
      annualImportsList
      businessStructureList
      countries
      countriesWithId
      currencyValues
      docsRelease
      docsRouting
      paymentTerms
      paymentDurations
      productCategory
      states
    }
  }
`;
